import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Observable, catchError, finalize, tap, throwError } from 'rxjs';
import { LoggerService } from './logger.service';

@Injectable()
export class HttpLoggingInterceptor implements HttpInterceptor {

  constructor(
    private loggerService: LoggerService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const startTime = Date.now();
    let status: 'Success' | 'Error' = 'Success';

    return next.handle(request).pipe(
      tap(),
      catchError((error: HttpErrorResponse) => {
        if(isDevMode()) {
          console.error('POC - Error from HTTP Interceptor', error);
        }
        status = 'Error';
        this.loggerService.error(`HTTP Request Error ${request.method} ${request.url}`, {
          url: request.url,
          method: request.method,
          status: error.status,
          message: error.message
        });
        return throwError(() => error);
      }),
      finalize(() => {
        const elapsedTime = Date.now() - startTime;
        this.loggerService.info(`HTTP Request ${request.method} ${request.url}`, {
          url: request.url,
          duration: `${elapsedTime}ms`,
          method: request.method,
          status,
          requestBody: request.body,
          requestHeaders: request.headers 
        });
      })
      
    ) 
  }
}

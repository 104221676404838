<div *ngIf="seleccionQA" class="container mt-5">
    <div class="row justify-content-center">
        <div class="col-md-8">
            <button k-button class="btn btn-primary" style="margin-top: 10px;right: 20%;" (click)="qa('')">
                <i class="icon-arrow-right"></i>
                QA 1
            </button>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-8">
            <button k-button class="btn btn-primary" style="margin-top: 10px;right: 20%;" (click)="qa('02')">
                <i class="icon-arrow-right"></i>
                QA 2
            </button>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-md-8">
            <button k-button class="btn btn-primary" style="margin-top: 10px;right: 20%;" (click)="qa('03')">
                <i class="icon-arrow-right"></i>
                QA 3
            </button>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-8">
            <button k-button class="btn btn-primary" style="margin-top: 10px;right: 20%;" (click)="qa('04')">
                <i class="icon-arrow-right"></i>
                QA 4
            </button>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-8">
            <button k-button class="btn btn-primary" style="margin-top: 10px;right: 20%;" (click)="seleccionQA=!seleccionQA">
                <i class="icon-arrow-right"></i>
                Atras
            </button>
        </div>
    </div>
</div>
<div *ngIf="!seleccionQA" class="container mt-5">
    <div class="row justify-content-center">
        <div class="col-md-8">
            <div class="text-left mb-4">
                <h3>ELIGE TU MEDIO DE PAGO</h3>
                <p class="lead" style="font-size: 18px;" data-dd-privacy="mask">Estimado cliente, tiene {{segundos}} segundos para iniciar el
                    pago</p>
                <p style="font-size: 20px;">Paga con tus tarjetas de crédito o débito, o transferencia bancaria.</p>
            </div>
        </div>
    </div>
    <div *ngIf="metodo.includes('Webpay')" class="row justify-content-center">
        <div class="col-md-8">
            <div class="medio-pago" [class.active]="active" [class.inactive]="inactive"
                style="padding: 20px; margin-top: 20px;">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="story-content flex-grow-1">
                        <k-checkbox-group (change)="cambioW($event)" [options]="OWebpay"></k-checkbox-group>
                    </div>
                    <img class="ml-3" style="height: 40px;" src="../../../assets/img/webpay.png">
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="metodo.includes('Khipu')" class="row justify-content-center">
        <div class="col-md-8">
            <div class="medio-pago" [class.active]="active2" [class.inactive]="inactive2"
                style="padding: 20px; margin-top: 20px;">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="story-content flex-grow-1">
                        <k-checkbox-group (change)="cambioK($event)" [options]="OKhipu"></k-checkbox-group>
                    </div>
                    <img class="ml-3" style="height: 40px;" src="../../../assets/img/khipu.png">
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="metodo.includes('MercadoPago')" class="row justify-content-center">
        <div class="col-md-8">
            <div class="medio-pago" [class.active]="active3" [class.inactive]="inactive3"
                style="padding: 20px; margin-top: 20px;">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="story-content flex-grow-1">
                        <k-checkbox-group (change)="cambioM($event)" [options]="OMercado"></k-checkbox-group>
                    </div>
                    <img class="ml-3" style="height: 40px;" src="../../../assets/img/mercadoPago.png">
                </div>
            </div>
        </div>
    </div>
    <!-- Nuevo bloque para Forpay -->
    <div *ngIf="metodo.includes('Forpay')" class="row justify-content-center">
        <div class="col-md-8">
            <div class="medio-pago" [class.active]="active4" [class.inactive]="inactive4"
                style="padding: 20px; margin-top: 20px;">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="story-content flex-grow-1">
                        <k-checkbox-group (change)="cambioF($event)" [options]="OForpay"></k-checkbox-group>
                    </div>
                    <img class="ml-3" style="height: 20px;" src="../../../assets/img/forpay.png" alt="Forpay logo">
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="veralerta">
        <k-alert [type]="type" [message]="message" [closeable]="closeable"></k-alert>
    </div>
    <div class="row justify-content-center mt-3">
        <div class="col-md-8 d-flex justify-content-end">
            <button k-button class="btn btn-primary" style="margin-top: 10px;margin-bottom: 10px;" (click)="pagar()">
                <i class="icon-arrow-right"></i>
                Pagar
            </button>
        </div>
    </div>
    <div *ngIf="isQA" class="row justify-content-center mt-3">
        <div class="col-md-8 d-flex justify-content-end">
            <button k-button class="btn btn-primary" style="margin-top: 10px;" (click)="cambiarambiete()">
                <i class="icon-arrow-right"></i>
                cambiar ambiente
            </button>
        </div>
    </div>
</div>
<div [className]="cargando ? 'fondooscuro':'oculta'">
    <div [className]="cargando ? 'fondooscuro':'oculta'"></div>
    <div [className]="cargando ? 'contenedormodal':'oculta'">
        <k-loader [color]="color" [size]="size"></k-loader>
        <p style="margin-top: 15px;">Estamos procesando tu solicitud {{punto}}</p>
    </div>
</div>

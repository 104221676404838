import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Pasarela } from 'src/app/Service/pasarela.service';

@Component({
  selector: 'app-termino-khipu',
  templateUrl: './termino-khipu.component.html',
  styleUrls: ['./termino-khipu.component.scss']
})
export class TerminoKhipuComponent implements OnInit {
  constructor(private route: ActivatedRoute, public pasarelaservice: Pasarela) { }
  punto = "."
  cargando = true
  color = "#00ADEF"
  colorb = "primary"
  size = "60px"

  ngOnInit(): void {
    let token = this.route.snapshot.paramMap.get('token') ?? '';
    setInterval(() => { this.puntos() }, 350)
    let llamadaEnCurso = false; 
    setInterval(() => {
      if (!llamadaEnCurso) {
        llamadaEnCurso = true;
        this.pasarelaservice.login().subscribe((data) => {
          this.pasarelaservice.finalizar(token, data.access_token).subscribe((data) => {
            if (data.retorno)
              if(data.retorno === 'cerrar')
                window.close();
              else
                window.location.href = data.retorno;
            llamadaEnCurso = false; 
          });
        });
      }
    }, 5000);

  }
  puntos() {
    this.punto = this.punto.length > 3 ? "." : this.punto + ".";
  }
}

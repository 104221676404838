import { RouterModule, Routes } from '@angular/router';
import { PasarelaPagoComponent } from './component/pasarela-pago/pasarela-pago.component';
import { TerminoWebpayComponent } from './component/termino-webpay/termino-webpay.component';
import { TerminoKhipuComponent } from './component/termino-khipu/termino-khipu.component';
import { ExitomComponent } from './component/exitom/exitom.component';

const routes: Routes = [
  {path:'pasarelapago/:token',component:PasarelaPagoComponent},
  {path:'terminowebpay',component:TerminoWebpayComponent},
  {path:'terminokhipu/:token',component:TerminoKhipuComponent},
  {path:'exitom/:token',component:ExitomComponent}
];
export const appRoutingModule = RouterModule.forRoot(routes);

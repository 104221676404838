// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false, 
  environment:"QA",
  primeraetapa:{
    url:"https://apimazqa.grupokaufmann.com/qas/v1/tu-pago/aplicacion/medios/",
    apikey:"1dfab17350ba4a0e9303e4d567d28bb8"
  },
  auth:{
    url:"https://apimazqa.grupokaufmann.com/qas/v1/tu-pago/auth/login",
    apikey:"1dfab17350ba4a0e9303e4d567d28bb8"
  },
  pagarWebpay:{
    url:"https://apimazqa.grupokaufmann.com/qas/v1/tu-pago/webpay/iniciar/",
    apikey:"1dfab17350ba4a0e9303e4d567d28bb8"
  },
  pagarKhipu:{
    url:"https://apimazqa.grupokaufmann.com/qas/v1/tu-pago/khipu/iniciar/",
    apikey:"1dfab17350ba4a0e9303e4d567d28bb8"
  },
  pagarMercado:{
    url:"https://apimazqa.grupokaufmann.com/qas/v1/tu-pago/mercado-pago/iniciar/",
    apikey:"1dfab17350ba4a0e9303e4d567d28bb8"
  },
  pagarForpay:{
    url:"https://apimazqa.grupokaufmann.com/qas/v1/tu-pago/forpay/iniciar/",
    apikey:"1dfab17350ba4a0e9303e4d567d28bb8"
  },
  retornar:"https://tupagoqa.kaufmann.cl:10001/PasarelaDePago/rest/servicios/retornar",
  finalizarpago:{
    url:"https://apimazqa.grupokaufmann.com/qas/v1/tu-pago/webpay/terminar/",
    apikey:"1dfab17350ba4a0e9303e4d567d28bb8"
  },
  login:{
    user:"pasarela",
    llave:"zN6MvTYfJ5"
  },
  datadog: {
    clientToken: 'pubd78b37b550bf63e8b90988273ed59ec8',
    applicationId: 'e78cf5e6-304e-436e-a556-bdbf3e2a01b6',
    site: 'datadoghq.com',
    service: 'pasarela-de-pago---dev/qa',
    env: 'qas1',
    enableDatadogLogging: true,
    version: '1.0.5',
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      (url: string) => url.startsWith('https://apimazqa.grupokaufmann.com') || url.startsWith('https://tupagoqa.kaufmann.cl')
  ],
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-exitom',
  templateUrl: './exitom.component.html',
  styleUrls: ['./exitom.component.scss']
})
export class ExitomComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }
  punto = "."
  cargando = true
  color = "#00ADEF"
  colorb= "primary"
  size = "60px"
  ngOnInit(): void {
    setInterval(() => {this.puntos()},350)
    let token = this.route.snapshot.paramMap.get('token') ?? '';
    console.log(token)
  }
  puntos(){
    this.punto = this.punto.length > 3 ? "." : this.punto + ".";
  }

}

import { Injectable } from '@angular/core';
import { datadogLogs as datadog } from '@datadog/browser-logs';
import { DefaultPrivacyLevel, datadogRum } from '@datadog/browser-rum';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  private initializaed = false;

  constructor() { 
    if(!environment.datadog.enableDatadogLogging || !environment.datadog) {
      return;
    }

    datadog.init({
      clientToken: environment.datadog.clientToken,
      site: environment.datadog.site,
      service: environment.datadog.service,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      env: environment.datadog.env,
    });

    datadogRum.init({
      clientToken: environment.datadog.clientToken,
      applicationId: environment.datadog.applicationId,
      site: environment.datadog.site,
      service: environment.datadog.service,
      env: environment.datadog.env,
      version: environment.datadog.version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: environment.datadog.trackResources,
      trackLongTasks: environment.datadog.trackLongTasks,
      trackUserInteractions: environment.datadog.trackUserInteractions,
      defaultPrivacyLevel: environment.datadog.defaultPrivacyLevel as DefaultPrivacyLevel,
      traceSampleRate: 100,
      allowedTracingUrls: environment.datadog.allowedTracingUrls
    });

    this.initializaed = true;
  }


  debug(message: string, context?: { [x: string]: unknown})  {
    if(this.initializaed) {
      datadog.logger.debug(message, context)
    }
  }

  info(message: string, context?: { [x: string]: unknown})  {
    if(this.initializaed) {
      datadog.logger.info(message, context)
    }
  }

  warn(message: string, context?: { [x: string]: unknown})  {
    if(this.initializaed) {
      datadog.logger.warn(message, context)
    }
  }

  error(message: string, context?: { [x: string]: unknown})  {
    if(this.initializaed) {
      datadog.logger.error(message, context);
      datadogRum.addError(new Error(message), context);
    }
  }

}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { appRoutingModule } from './app-routing.module';
import { PasarelaPagoComponent } from './component/pasarela-pago/pasarela-pago.component';
import { NgxBsKaufmannModule } from "ngx-bs-kaufmann";
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TerminoWebpayComponent } from './component/termino-webpay/termino-webpay.component';
import { TerminoKhipuComponent } from './component/termino-khipu/termino-khipu.component';
import { HttpLoggingInterceptor } from './Logger/http-logging.interceptor';
import { ExitomComponent } from './component/exitom/exitom.component';

@NgModule({
  declarations: [
    AppComponent,
    PasarelaPagoComponent,
    TerminoWebpayComponent,
    TerminoKhipuComponent,
    ExitomComponent
  ],
  imports: [
    NgxBsKaufmannModule,
    BrowserModule,
    appRoutingModule,
    HttpClientModule
  ],
  providers: [  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpLoggingInterceptor,
    multi: true
  },],
  bootstrap: [AppComponent]
})
export class AppModule { }

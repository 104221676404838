import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Pasarela } from '../../Service/pasarela.service';

@Component({
  selector: 'app-termino-webpay',
  templateUrl: './termino-webpay.component.html',
  styleUrls: ['./termino-webpay.component.scss']
})
export class TerminoWebpayComponent implements OnInit {

  constructor(private route: ActivatedRoute,public pasarelaservice:Pasarela) { }
  punto = "."
  cargando = true
  color = "#00ADEF"
  colorb= "primary"
  size = "60px"
  ngOnInit(): void {
    setInterval(() => {this.puntos()},350)
    this.route.queryParams.subscribe(params => {
      var tokenWs = params['token_ws'];
      if(!tokenWs) tokenWs = params['TBK_TOKEN'];
      if (tokenWs) {
        this.pasarelaservice.login().subscribe((data)=>{
          this.pasarelaservice.finalizar(tokenWs,data.access_token).subscribe((data)=>{
            if(data.retorno === 'cerrar')
              window.close();
            else
              window.location.href = data.retorno;
          })
        })
      }
    });
  }
  puntos(){
    this.punto = this.punto.length > 3 ? "." : this.punto + ".";
  }
}